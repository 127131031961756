import type { HttpClient } from '../types/controller';
import type { PublicMember, RolesMap } from '../types';

export class ProfileCardMiddlewareService {
  constructor(private readonly httpClient: HttpClient) {}

  async getMember(uid: string) {
    const { data } = await this.httpClient.get<PublicMember>(
      `/_serverless/profile-card-middleware/member/${uid}`,
    );

    return data;
  }

  async getRolesMap() {
    const { data } = await this.httpClient.get<RolesMap>(
      `/_serverless/profile-card-middleware/roles-map`,
    );

    return data;
  }
}
